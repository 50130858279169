import {React} from 'react';
import ReactDOM from 'react-dom/client';
import 'styles/global.css';
import { App, ConfigProvider } from 'antd';
import Apps from './App';
import { SITE } from 'config/config';
import { CONST_SITE } from 'utils/common/const';

const root = ReactDOM.createRoot(document.getElementById('root'));
const colorSite =
  SITE === CONST_SITE.NEW88 ? '#ff9800' : SITE === CONST_SITE.SHBET ? '#2f7899' : '#ff9900';

root.render(
  <ConfigProvider
    prefixCls="okvip"
    iconPrefixCls=""
    theme={{
      token: {
        colorPrimary: colorSite,
        colorInfo: colorSite,
        borderRadius: 4
      },
      components: {
        Button: {
          colorPrimary: colorSite
        },
        Layout: {
          headerHeight: 20
        }
      }
    }}
  >
    <App
      notification={{
        placement: 'top'
      }}
    >
      <Apps />
    </App>
  </ConfigProvider>
);
