import { Suspense, useEffect } from 'react';
import AppRoutes from './AppRoutes';
import { BrowserRouter } from 'react-router-dom';
import { RecoilRoot } from 'recoil';
import Loading from 'components/Loading';
import { SITE } from './config/config';
import favicon_new88 from './assets/images/favicon-new88.ico';
import favicon_shbet from './assets/images/favicon-shbet.ico';

const favicons = {
  new88: favicon_new88,
  shbet: favicon_shbet,
};
function Apps() {
  useEffect(() => {
    const faviconElement = document.querySelector("link[rel*='icon']") || document.createElement('link');
    faviconElement.type = 'image/png';
    faviconElement.rel = 'icon';
    faviconElement.href = favicons[SITE];
  }, []);
  return (
    <RecoilRoot>
      <BrowserRouter>
        <Suspense fallback={<Loading />}>
          <AppRoutes />
        </Suspense>
      </BrowserRouter>
    </RecoilRoot>
  );
}

export default Apps;
